@import '@crystal-eyes/helpers.scss';

$max-name-width: 8em;

.title {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  background-color: #f5f5f5;
  color: #6e6e6e;

  border-bottom: 1px solid #ddd;
  padding: 0.5rem 0.5rem;

  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1em;
}

.actor {
  display: flex;
  position: relative;
  align-items: center;
  column-gap: 0.5em;
  color: black;
  padding-right: 0.25em;

  img {
    border-radius: 50%;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.notificationContent {
  line-height: 1em;
}

.viewCta {
  position: absolute;
  right: 1em;
  color: #2ca7e4;
}

.notificationItem {
  display: grid;
  grid-template-columns: $max-name-width 20em 5em;
  font-size: 0.8rem;
  align-items: center;
  padding: 0.5rem 1em;
}

.notificationWrapper {
  a {
    color: #6e6e6e;
  }

  a:hover {
    background-color: rgb(246, 250, 256);
    color: #6e6e6e;
  }
}

.clearNotifsArea {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 2em;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: #2ca7e4;

  &:hover {
    background-color: rgb(246, 250, 256);
  }
}

.noNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.5rem;
}

.loadingArea {
  width: 100%;
  padding: 2em 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  position: relative;
  border: 1px solid #eee;
  border-radius: 3px;
  min-width: 20em;
  max-width: $max-name-width + 25em;
  line-height: 1rem;
  background-color: white;

  a {
    text-decoration: none;
  }
}

.icon {
  @include svg-bold();
}
