@import '@crystal-eyes/constants.scss';
$search-height: 2.5em;

.loadingArea {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1em;
}

.ctaText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;

  padding: 1em;
}

.noResultsText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 0.5rem;

  padding: 1em;
}

.addProfilesLink {
  color: $action-blue;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 4px;
}

.searchArea {
  position: relative;
  width: 100%;
  height: $search-height;
  font-size: 0.9rem;
  line-height: 1rem;

  @media print {
    visibility: hidden;
  }

  & > img {
    position: absolute;
    left: 8px;
    top: 12px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #c4c4c4;
    padding-left: 28px !important;
    font-family: var(--font-lato-400);
    border-radius: 3px;

    &::placeholder {
      font-family: var(--font-lato-400);
      letter-spacing: 0.06rem;
      font-size: 0.9rem;
    }

    &:focus {
      outline: none;
      border: 1px solid #2ca7e4;
    }
  }
}

.resultsArea {
  position: absolute;
  top: $search-height;
  left: 0;
  right: 0;
  z-index: 10000;

  border-right: 1px solid #cecece;
  border-left: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  border-radius: 3px;
  background-color: white;

  a {
    text-decoration: none;
  }

  :global(.result-item) {
    display: flex;
    align-items: center;
    column-gap: 0.5em;
    padding: 1em;
    line-height: 1em;
    color: black;

    border-bottom: 1px solid #dedede;

    &:hover {
      cursor: pointer;
      color: white;
      color: black;
      background-color: #eee;

      .resultMetadata > :nth-child(2) {
        color: #2ca7e4;
      }
    }

    img {
      object-fit: cover;
      border-radius: 50%;
    }
  }

  :global(.attrib-pills) {
    display: flex;
    column-gap: 0.5em;

    margin-top: 0.2rem;

    :global(.pill) {
      color: white;
      padding: 0.2em 0.5em;
      border-radius: 6px;
      font-size: 0.8em;
    }
  }

  :global(.result-metadata) {
    display: flex;
    flex-direction: column;
    row-gap: 3px;

    & > :nth-child(2) {
      color: #9e9e9e;
    }
  }

  :global(.name-wrapper) {
    display: flex;
  }
}
